// palette for Casino Lust
$bg-color: #260101;
$text-color: #F5EDE6;
$primary: #F20505;
$success: #6A9E39;
$info: #730202;
$danger: #D9303E;


$secondary: mix($bg-color, #fff, 50%);
$input-placeholder-color: $secondary;


//menu in primary color
.sticky-sidebar {
  .nav-link:hover, .active-link {
    color: $primary !important;
    .variable-svg-icon {
      background-color: $primary !important;
    }
  }
}



.btn-primary {
  --bs-btn-color: $text-color !important;
  --bs-btn-hover-color: $text-color !important;
  --bs-btn-active-color: $text-color !important;
}

.btn-outline-primary {
  --bs-btn-hover-color: $text-color !important;
  --bs-btn-active-color: $text-color !important;
}
